import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#deti"> &gt; Radca(DETI)</a>
            <a href="/radca/deti/upchaty_nos_u_detC3AD/" className="sel">
              {" "}
              &gt; Upchatý nos u detí
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/GettyImages-135538267.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Upchatý nos u detí</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    <b>
                      Zápal nosovej sliznice (ľudovo nádcha) patrí medzi
                      najbežnejšie zdravotné ťažkosti, ktoré nás potrápia
                      prinajmenšom raz ročne. Deti „dostanú“ nádchu oveľa
                      rýchlejšie ako dospelí a oveľa horšie ju aj znášajú.
                      Sťažené dýchanie, upchatý nos a nepokojný spánok neustále
                      prerušovaný nepriechodným nosom tvoria len časť problémov,
                      ktoré počas infekcie trápia deti a ich rodičov. Ako teda
                      zmierniť tieto príznaky?{" "}
                    </b>
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Nádcha obvykle signalizuje{" "}
                  <a href="/slovnik_pojmov/#prechladnutie" target="_blank">
                    prechladnutie
                  </a>
                  , alergickú reakciu alebo chrípku. Začína sa kýchaním, pocitom
                  upchatého nosa, potom sa objaví vodnatý výtok, ktorý približne
                  po štyroch dňoch zhustne. Tieto príznaky často sprevádza
                  porucha čuchu, nevnímame vôňu ani chuť jedla, nemáme chuť do
                  jedla, sme podráždení a cítime sa čoraz horšie. Tieto ťažkosti
                  nemožno ignorovať ani u detí.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Deti môže trápiť vírusová nádcha (prekonávajú ju niekoľkokrát
                  v roku, kým ešte nemajú plne vyvinutý imunitný systém) alebo
                  bakteriálna nádcha, označovaná aj ako hnisavá (objavuje sa
                  bielo-žltý alebo zelený výtok, ktorý si vyžaduje antibiotickú
                  liečbu). Zápal nosovej sliznice možno liečiť viacerými
                  domácimi metódami (napr. inhaláciami záparov z byliniek,
                  mliekom s medom, cesnakom alebo zázvorom), prípadne liekmi na
                  zmiernenie opuchu nosovej sliznice s obsahom{" "}
                  <a href="/slovnik_pojmov/#xylometazolin" target="_blank">
                    xylometazolínu
                  </a>
                  , ako je napríklad{" "}
                  <a href="/lieky/olynth_05/" target="_blank">
                    OLYNTH<sup>®</sup> 0,05 %
                  </a>{" "}
                  alebo{" "}
                  <a href="/lieky/olynth_ha_05/" target="_blank">
                    OLYNTH<sup>®</sup> HA 0,05 %
                  </a>
                  . Pomáhajú odstrániť nahromadené hlieny a obnoviť voľné
                  dýchanie tým, že sťahujú cievky nosovej sliznice a zmierňujú
                  opuch nosovej sliznice.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <img
                    alt="Predídete komplikáciám nádchy detí, použite OLYNTH® k uvoľneniu nosa."
                    src="/web/kcfinder/uploads/images/shutterstock_286005839(2).jpg"
                    style={{
                      width: "380px",
                      height: "253px",
                      "border-width": "2px",
                      "border-style": "solid",
                      float: "left",
                      margin: "5px 10px 10px 0px",
                      border: "2px solid #fff"
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Ak máme doma choré dieťa (a nielen vtedy), nesmieme zabúdať na
                  pravidelné vetranie domácnosti a zabezpečenie správnej
                  vlhkosti v miestnosti (tu sa osvedčili zvlhčovače alebo mokrý
                  uterák na radiátore). V boji s nádchou pomôže aj fyziologický
                  roztok kuchynskej soli alebo morská voda v aerodisperzii,
                  ktoré zriedia hlieny, masť z majoránu s protizápalovými a
                  antibakteriálnymi účinkami, ako aj pravidelné čistenie nosa
                  (napr. u malých detí pomocou tzv. hrušky alebo odsávačky).
                  Nemenej dôležité je piť veľké množstvo tekutín (vody).
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  V jesennom a zimnom období by sme mali venovať osobitnú
                  pozornosť deťom do 12 rokov, ktoré ešte nemajú plne vyvinutý
                  imunitný systém. Nádcha, ktorá sa objavuje u detí v tomto
                  veku, nesie so sebou veľké nebezpečenstvo komplikácií,
                  napríklad zápalu ucha. Iná anatómia{" "}
                  <a href="/slovnik_pojmov/#sluchova_trubica" target="_blank">
                    Eustachovej trubice
                  </a>
                  &nbsp;u malých detí ako u dospelých zvyšuje náchylnosť na
                  ochorenia na zápal ucha.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <img
                    className="resizeImage"
                    alt="Predídete komplikáciám nádchy detí, použite OLYNTH® k uvoľneniu nosa."
                    src="/web/kcfinder/uploads/images/sk/shutterstock_93339907(1).jpg"
                    style={{
                      width: "380px",
                      height: "205px",
                      float: "right",
                      margin: "5px 0px 10px 10px"
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                 Sluchová trubica, ktorá spája stredné ucho s hornou časťou hrdla, je u detí široká a neustále otvorená, preto sa do nej veľmi rýchlo dostanú nahromadené hlieny. V takomto prípade je možné použiť lieky{" "}
                  <a href="/lieky/olynth_05/" target="_blank">
                    OLYNTH<sup>®</sup> 0,05 %
                  </a>{" "}
                  alebo{" "}
                  <a href="/lieky/olynth_ha_05/" target="_blank">
                    OLYNTH<sup>®</sup> HA 0,05 %
                  </a>
                  alebo OLYNTH® PLUS 0,5 mg/50 mg/ml, ktoré vďaka obsahu{" "}
                  <a href="/slovnik_pojmov/#xylometazolin" target="_blank">
                    xylometazolínu
                  </a>
                  &nbsp;zmierňujú opuch nosovej sliznice a pomáhajú odstraňovať sekrét (aby neprenikol do sluchovej trubice). Znižuje sa tým riziko šírenia infekcie.
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <span className="s1">Literatúra::&nbsp;</span>
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    Górnicka Jadwiga,{" "}
                    <em>Nie daj się przeziębieniu! Receptury natury</em>, AWM
                    Morex, 2014.{" "}
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    Iwanowicz-Palus Grażyna,{" "}
                    <em>Ciąża, poród, macierzyństwo</em>, Wydawnictwo Rea, 2013
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    Latkowski Bożydar,{" "}
                    <em>Farmakoterapia w otorynolaryngologii</em>, PZWL.
                  </span>
                </p>
                <p className="p1">
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    Tylżanowska-Kisiel Joanna, <em>Przeziębienie i grypa</em>,
                    Helion, 2007.
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a
                  href="/radca/deti/imunita_dietata_dbajte_a_posilnujte/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/shutterstock_1668728821.jpg)" }} />
                  <span className="title">
                    Imunita dieťaťa – dbajte a posilňujte
                  </span>
                </a>
                <a
                  href="/radca/deti/Boj_s_infekciami_nadcha_a_kasel_u_dietata/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/shutterstock_494479543.jpg)" }} />
                  <span className="title">
                    Boj s infekciami – nádcha a kašeľ u dieťaťa
                  </span>
                </a>
                <a href="/radca/deti/ako_naucit_dieta_siakat/" className="box">
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/dzieci_poradnik_03.jpg)" }} />
                  <span className="title">Ako naučiť dieťa siakať?</span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}

      </div>
    );
  }
}

export default Page;
